<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    :append-to-body="true"
    width="40%"
    style="text-align:left;"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
      <el-form-item label="所属学校" prop="schoolId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.schoolId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="时间范围" prop="timeRanges">
        <el-date-picker
            v-model="formModel.timeRanges"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          ></el-date-picker>
        </el-form-item>
       </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="exportXls" :loading="loading">导出</el-button>
    </span>
  </el-dialog>

</template>
<script>
import Constant from "@/constant";
import companyInfoApi from "@/api/base/companyInfo";
import personHealthLedgerApi from "@/api/student/personHealthLedger";
import SelectTree from "@/components/SelectTree";
export default {
  props: ["businessKey", "title"],
  data() {
      return{
        formModel: {},
        ruleValidate: {
          schoolId: [{ required: true, message: "学校不能为空", trigger: "blur" }],
          timeRanges: [{ required: true, message: "时间范围不能为空", trigger: "blur" }],
        },
        showDialog: true,
        companyResult: [],
        loading: false,
        submitting: false,
        downloadLoading: false,
        props: {
          // 配置项（必选）
          value: "id",
          label: "name",
          children: "children",
        },
      }
  },
  methods:{
    closeDialog() {
      this.$emit("close", false);
    },
    loadTree() {
      var formData = new FormData();
      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    exportXls() {
      var self = this;

      //导出
      this.$refs["form"].validate(valid => {
        if (valid) {
          self.downloadLoading = true;
          self.loading = true;

          var formData = new FormData();

          formData.append("type", "student");
          formData.append("classId", self.formModel.schoolId);
          formData.append("timeRanges", this.formModel.timeRanges);

          personHealthLedgerApi.healthLedgerDateExportXls(formData).then(function(response) {
            var jsonData = response.data;

            self.downloadLoading = false;
            self.loading = false;

            if (jsonData.result) {
              self.$message({
                type: "success",
                showClose: true,
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000
              });
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message
              });
            }
          });
        }
      });
    }
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    this.loadTree();
  },
  components: {
    "el-select-tree": SelectTree
  }
}
</script>